@import "../../styles/css/common.scss";

.trans,
.titleName {
  align-self: center;
}

h1 {
  margin: 0px;
}

.titleName {
  color: #ffffff;
  font-size: 20px;
}

a.ant-typography:hover {
  text-decoration: underline;
}

img {
  height: 35px;
}

.notify {
  padding: 8px;
  border-bottom: 1px solid #dadada;
  height: 80px;
  border-left: 3px solid transparent;

  .titleNotify {
    font-weight: bold;
    font-size: 14px;
  }

  .iconNotify {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: -3px;
  }

  .warning1 {
    color: #FEC943 !important;
  }

  .warning2 {
    color: #F27718 !important;
  }

  .warning3 {
    color: #ED3A3E !important;
  }

  .warning4 {
    color: #ED3A3E !important;
  }

  .recovery {
    color: #66AF1C !important;
  }

  .contentNotify {
    font-weight: normal;
    font-size: 14px;
    color: $bg--primary;
  }

  .timeNotify {
    font-weight: normal;
    font-size: 10px;
    margin-left: 25px;
  }

  &:hover {
    border-left: 3px solid $bg--primary;
  }
}

.anticon-menu-unfold  {
  display: inline-block;
}

.select-office-prod {
  // margin-bottom: 8px !important;
}
.select-border-color-header {
  padding-top: 0;
  cursor: pointer;
  width: 168px;
  .basic-single {
    // border: 1px solid #DFE6EC;
    border-radius: 4px;
    height: 34px;
    .select__menu {
      font-size: 14px;
    }
    .select__control  {
      min-height: 0 !important;
      cursor: pointer;
      height: 32px;
      .select__indicators {
        height: 32px;
        .select__indicator-separator {
          background-color: white;
        }
      }
    }
  }
  align-items: center;
  display: flex;
  padding-right: 10px;
  .select__value-container  {
    height: 100%;
    position: inherit;
    .select__single-value {
      height: 32px;
      top: 22%;
      color: #252733;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
    }
    .css-b8ldur-Input, .css-1g6gooi {
      height: 12px;
      margin-top: -41px;
      // color: white;
    }
  }
}

.overlay-popover-notification {
  .ant-popover-content {
    .ant-popover-inner {
      min-width: 480px;
      .scrollable-box {
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
          height: 6px;            
          width: 6px;               
          border: 1px solid #d5d5d5;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: #bfbfbf;
          -webkit-border-radius: 10px;
          border-radius: 10px;
        }
        
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
          -webkit-border-radius: 10px;
          border-radius: 10px;
        }
        scrollbar-width: thin;
        scrollbar-color: #bfbfbf #d5d5d5;
      }
      .infinite-scroll-component {
        overflow: hidden !important;
        .titleNotify {
          display: flex;
          align-items: center;
        }
        .notify-content {
          max-width: 400px;
          margin-bottom: 0 !important;
        }
        .notify-title {
          max-width: 350px;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
.name-header-office {
  .header-left {
    height: 100%;
    padding-left: 20px;
    flex-wrap: nowrap;
    .dasboard-header {
      // max-width: 200px; 
      // .ant-typography-ellipsis {
      //   max-width: 200px;
      // }
      .title-dashboard {
        font-family:' Noto Sans JP';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #0080CB;
        margin-left: 8px;
      }
    }
    
    .dashboard-icon {
      height: "22px";
    }
    .devide {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 16px;
      .line {
        display: block;
        height: 27px;
        width: 1px;
        background: #DFE6EC;
      }
    }
    .label-select-office {
      margin-right: 8px;
      span {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #252733;
        position: relative;
        bottom: 3px;
      }
    }
    .bigTitlePage {
      margin-left: 0;
      .name-page {
        margin-bottom: 0 !important;
      }
    }
  }
}
// @media only screen and (max-width: 941px) {
//   .name-header-office {
//     .dasboard-header {
//       max-width: 150px !important;
//       .ant-typography-ellipsis {
//         max-width: 150px !important;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 850px) {
//   .name-header-office {
//     .dasboard-header {
//       max-width: 100px !important;
//       .ant-typography-ellipsis {
//         max-width: 100px !important;
//       }
//     }
//   }
// }