.directory-page {
    font-family: 'Noto Sans JP';
    font-style: normal;
    padding-top: 4px;
    margin-bottom: 20px;
    .title-page {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;  
        color: #252733;
    }
    .directory {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .office-name, .parent-page-link {
            text-decoration: underline;
            &:hover {
                color: #0080CB;
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .link-name {
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            font-weight: normal;
            color: #232A3D;
        }
        .disable-link {
            color: #232a3d;
        }
        .current-path {
            color: #A0A9B1;
        }
        .seperator {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #A0A9B1;
            margin: 0 8px;
        }
    }
}