@import "../../styles/css/common.scss";

.ant-layout-sider {
  background: $sidebar-bg--secondary !important;
  box-shadow: 6px 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  .rowNotMargin {
    background: $sidebar-bg--secondary;
  }
  .iconSideBar {
    color: $bg--gray;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-bottom: 5px;
  }
}
.ant-menu-dark.ant-menu-inline {
  margin-top: 80px !important;
}
// .ant-menu-inline .ant-menu-submenu {
//   border-bottom: 2px solid #486495;
// }
.noBorder {
  border: 0;
}
.ant-menu-item.customSubMenu,
.customSubMenu > .ant-menu-submenu-title {
  border-bottom: 2px solid #486495;
  height: 64px !important;
  line-height: 64px !important;
  margin: 0 !important;
  background: $sidebar-bg--secondary;
}
.ant-menu-item {
  .iconSideBar {
    color: $bg--gray;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-bottom: 5px;
  }
}
.customSubMenu.ant-menu-submenu-open > .ant-menu-submenu-title {
  // border-left: 8px solid $bg--blue;
  background-color: #0080CB;
}
.ant-layout-sider .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-layout-sider .customSubMenu.collapsed.ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: #0080CB !important;
  color: #fff !important;
}
.ant-layout-sider .customSubMenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: #0080CB !important;
  color: #fff !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.customSubMenu.collapsed.ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: #485064 !important;
  color: #fff !important;
}
.ant-layout-sider .ant-menu.ant-menu-dark, .ant-layout-sider .ant-menu-dark .ant-menu-sub, .ant-layout-sider .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #232A3D !important;
}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #2D354B !important;
}
.ant-menu-submenu .ant-menu-submenu-title {
  border-bottom: none;
}
.ant-menu-submenu-active {
  color: #FFFFFF !important;
  background: #485064 !important;
}
.ant-menu-item-active {
  background: #485064 !important;
}
hr.menu-bottom-divider {
  border-top: 1px solid #2C344A;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: calc(100% - 20px);
}
hr.sub-menu-bottom-divider {
  border-top: 1px solid #333C53;
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-top: 0px;
  margin-bottom: 0px;
  width: calc(100% - 20px);
}
.ant-layout-sider {
  box-shadow: none;
}
.ant-layout-sider ul.ant-menu > li.ant-menu-item-active, .ant-layout-sider ul.ant-menu > li.ant-menu-submenu-active {
  background: #0080CB !important;
}
.ant-menu-submenu-popup li.ant-menu-item{
  background: #2D354B;
}
// ul.ant-menu > li.ant-menu-item-active, ul.ant-menu > li.ant-menu-submenu-active {
//   background: #0080CB !important;
// }
// ul.ant-menu {
//   li.customMenuItem {
//     border-bottom: 1px solid #2C344A;
//     // padding-left: 5px !important;
//     // padding-right: 5px !important;
//   }
// }

// .customSubMenu span {
//   color: #FFFFFF;
// }
// .ant-menu-item-selected > a,
// .ant-menu-item-selected> span > a,
// .ant-menu-item-selected > img {
//   color: $sidebar-bg--primary !important;
// }
.customMenuItem {
  margin: 0px !important;
  height: 64px !important;
  line-height: 64px !important;
  background-color: $sidebar-bg--primary;
  left: 42px;
  font-size: 16px;
}
.ant-layout-sider-has-trigger {
  transition: 0.5s;
}

.downloadSidebar {
  position: absolute;
  left: 0;
  right: 0;
  top: 90vh;
}

.downloadSidebarMobile {
  position: absolute;
  left: 0;
  right: 0;
  top: 80vh;
}

.iconDownloadSideBar {
  width: 13px!important;
  height: 15px!important;
}

.ant-layout-sider .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .downloadSidebarLink.ant-menu-item-selected:not(.ant-menu-item-active) {
  background: #232A3D!important;
}

.ant-tooltip-inner a {
  color: #fff !important;
}