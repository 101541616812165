@import "../../styles/css/common.scss";

.footerText {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #6C757D;
}

.ant-layout-footer {
  padding: 10px 50px 10px 20px !important;
  border-top: 1px solid #e4dfdf;
}