@import "./styles/css/common.scss";

.App {
 /* text-align: center;*/
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: $bg--gray;
}
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
}
.slider-icon {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 8px;
}
.trigger {
  font-size: 18px;
  line-height: 54px!important;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: $bg--primary !important;
}
.trigger:hover {
  color: $bg--primary;
}
.site-layout .site-layout-background {
  background: #fff;
}
.infoUser {
  padding-right: 16px;
  padding-left: 16px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  .notify-radio-group { 
    .ant-badge-count {
      top: -5px;
    }
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-right: 36px;
    svg {
      width: 35px;
      height: 35px;
    }
  }
  .user-name {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
  }
  .signout-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-top: 12px;
  }
}

.homePage {
  border-right: 1px solid #e4dfdf;
}

.buttonClick {
  padding: 6px 8px;
}
.buttonClick:hover {
  background-color: #dbdbde;
  cursor: pointer;
}
.imgIcon {
  height: 22px;
  width: 22px;
}
.rowNotMargin {
  margin: 0px !important;
  height: 100%;
}
.text-start {
  text-align: start;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.input-text-start {
  text-align: start;
  //padding-left: 10px;
}
.text-gray {
  color: $bg--gray;
}
.text-size-14 {
  font-size: 13px;
}
.text-size-18 {
  font-size: 18px;
}
.text-size-10 {
  font-size: 10px;
}
.titlePage {
  color: $bg--gray;
  text-align: start;
  font-size: 14px;
}
.title {
  padding-bottom: 20px;
}
.bigTitlePage {
  color: $bg--primary;
  text-align: start;
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
}
.titleFormField {
  padding-bottom: 6px;
}
.ant-form-item-explain-error {
  position: absolute;
  top: 100%;
  font-size: 12px!important;
}
button.buttonIcon {
  padding: 0px 10px !important;
  font-size: 18px!important;
  height: auto !important;
  color: #0080CB!important;
}
span.buttonIcon > button {
  padding: 0px 10px !important;
  font-size: 18px!important;
  height: auto !important;
}

.buttonIcon:focus-visible {
  outline-width: 1px!important;
  outline-style: solid!important;
}

.flexRow {
  display: flex!important;
  justify-content: space-evenly!important;
}
