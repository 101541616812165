$bg--primary: #003d8d;
$bg--secondary: #002f6e;
$bg--info: #5aa8db;
$bg--info2: #01aec7;
$bg--blue: #104eed;
$bg--gray: #666666;
$bg--warning: #f27718;
$bg--warning2: #fec943;
$bg--black: #333333;
$bg--disabled: rgba(0, 0, 0, 0.25);
$bg--danger: #ed3a3e;
$bg--page: #fbfbfd;
$bg--success: #66af1c;
$sidebar-bg--primary: #232A3D;
$sidebar-bg--secondary: #232A3D;

textarea {
  resize: none;
}
.button {
  border-radius: 4px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  height: 38px !important;
  min-width: 100px;
  &:hover {
    color: $bg--primary !important;
    background: white !important;
    border-color: $bg--primary !important;
  }
  &--disabled {
    &:hover {
      color: $bg--disabled !important;
      background: white !important;
      border-color: $bg--disabled !important;
    }
    background-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  &--warning {
    &:hover {
      color: $bg--warning2 !important;
      background: white !important;
      border-color: $bg--warning2 !important;
    }
    background-color: $bg--warning2 !important;
    color: white !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  &--confirm {
    &:hover {
      color: $bg--info !important;
      background: white !important;
      border-color: $bg--info !important;
    }
    background-color: $bg--info !important;
    color: white !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  &--primary {
    &:hover {
      color: $bg--primary !important;
      background: white !important;
      border-color: $bg--primary !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    background-color: $bg--primary !important;
    color: white !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  &--success {
    &:hover {
      color: $bg--success !important;
      background: white !important;
      border-color: $bg--success !important;
    }
    background-color: $bg--success !important;
    color: white !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
}

ul.ant-pagination {
  li {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  li.ant-pagination-item-active {
    background-color:  #0080CB;
    a {
      color: #FFFFFF;
    }
  }
  li.ant-pagination-prev, li.ant-pagination-next {
    button {
     & span > svg {
      color: #252733;
      font-weight: bold;
     }
    }
  }
  li.ant-pagination-disabled {
    button {
     & span > svg {
      color: #6C757D !important ;
      font-weight: bold !important;
     }
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  overflow: hidden;
}



.count-records {
  color: #000000;
  margin-bottom: 8px;
}

.confirm-common {
  .ant-modal-confirm-btns {
    display: flex;
  }
  top: 35% !important;
  .btn {
    border-radius: 2px;
    span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        letter-spacing: 0 !important;
    }
}
.btn-primary {
    background: #0080CB !important;
    span {
      color: #FFFFFF;
    }
    &:hover, &:focus {
      background: #00A0FE !important;
    }
    &:active {
      background: #0071B4 !important;
      border: 3px solid #98D9FF;
    }
  }
  .btn-outline {
    border: 1px solid #0080CB;
    box-sizing: border-box;
    span {
        color: #0080CB;
    }
    &:hover, &:focus {
      background: #0080CB !important;
      border: none !important;
      span {
          color: #FFFFFF;
      }
  }
    &:active {
      background: #0071B4 !important;
      border: 3px solid #98D9FF;
      span {
        color: #FFFFFF;
      }
    }
  }
}

.html-format-text {
  max-width: 250px !important;
  white-space: break-spaces !important;
  margin: 0 !important;
  font-family: "Noto Sans JP", sans-serif !important;
  color: black;
}

.plus-icon {
  font-size: 1.2em;
  padding-right: 8px;
  font-weight: bolder;
  text-transform: uppercase;
}

.loading-app {
  padding-top: 20%;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
}
.mobile {
  width: 46%;
  margin: auto;
}
.disable-elm {
  pointer-events: none;
  opacity: 0.4;
}

.ant-table-body {
  overflow-y: auto !important;
  // overflow-x: hidden !important;
  max-height: 500px;
  td {
    div {
      width: 100%;
    }
  }
}

.modal_warning_leave_page {
  width: 416px !important;
  .ant-modal-content {
    width: 416px;
    height: 164px;
    .modal-title {
      display: inline-flex;
      position: absolute;
      width: 80%;
      overflow: hidden;
      color: rgba(0,0,0,.85);
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
    }
    .modal-content-icon {
      color: #faad14; 
      margin-right: 16px;
      font-size: 22px;
    }
  }
  .ant-modal-footer {
    border-top: none !important;
    display: flex;
    justify-content: flex-end;
  }
}
.ant-table-body::-webkit-scrollbar, .react-tag-input::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 6px;            
  width: 5px;               
  border: 1px solid #d5d5d5;
}

.ant-table-body::-webkit-scrollbar-thumb, .react-tag-input::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.ant-table-body::-webkit-scrollbar-track, .react-tag-input::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.ant-table-body {
  scrollbar-width: thin!important;
  scrollbar-color: #bfbfbf #d5d5d5!important;
}

.desktop {
  width: 100%;
  margin: auto;
}
// .no-error-display {
//   position: relative;
// }
// .no-error-display .ant-form-item-explain-error {
//   position: absolute;
//   width: 300px;
//   top: 34px;
//   z-index: 999;
//   font-size: 12px;
// }

.has-error-validate .ant-form-item-explain-error {
  font-size: 12px;
}

.has-error-validate .marginTopError {
  margin-top: 20px !important;
}

.three-dots {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* no of lines */
  text-overflow: ellipsis;
  overflow: hidden !important;
  max-width: 150px;
}
/*.no-error-display {
  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner {
      background-color: #f5f5f5 !important;
      border-color: #fff !important;
    }
  }
}*/

.truncated {
  display: inline-block;
  max-width: 100%;
  width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0;
}

.camera-namex {
  display: inline-block;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap;
}
