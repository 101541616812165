.btn {
    border-radius: 2px;
    span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        letter-spacing: 0 !important;
    }
}
.btn-primary {
    background: #0080CB !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    span {
        color: #FFFFFF;
    }
    &:hover, &:focus {
        background: #00A0FE !important;
    }

    &:active {
        background: #0071B4 !important;
        border: 3px solid #98D9FF;
    }
}

.btn-outline {
    border: 1px solid #0080CB;
    box-sizing: border-box;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    span {
        color: #0080CB;
    }
    &:hover, &:focus {
        background: #0080CB !important;
        border: 1px solid transparent !important;
        span {
            color: #FFFFFF;
        }
    }
    &:active {
        background: #0071B4 !important;
        border: 3px solid #98D9FF;
        span {
            color: #FFFFFF;
        }
    }
}

.btn-disabled, button--disabled {
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}