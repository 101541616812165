@import "../../styles/css/common.scss";
.login {
  min-height: calc(100vh);
  background: url("../../styles/image/bg_login.png") no-repeat right top;
  background-size: calc(100vh + 200px);
  background-color: #fafafa;
  .rectangle {
    position: fixed;
    top: calc(50vh);
    left: -100px;
    width: 231.52px;
    height: 231.52px;
    background: #5aa8db;
    opacity: 0.07;
    border-radius: 40px;
    transform: rotate(45deg);
  }
  .content {
    padding: 80px;
    text-align: start;
    min-height: calc(100vh);
  }
  .big_title {
    color: $bg--primary;
    font-weight: bold;
    font-size: 54px;
  }
  .middle_title {
    color: $bg--info;
    font-weight: normal;
    font-size: 34px;
    padding-top: 16px;
  }
  .small_title {
    color: $bg--gray;
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 48px;
  }
  .ant-form {
    font-size: 16px;
  }
  .ant-input-affix-wrapper {
    padding: 0 11px;
  }
  .error_title {
    color: $bg--danger;
    font-weight: normal;
    font-size: 20px;
    padding-bottom: 48px;
    padding-top: 16px;
  }
}
.header {
  position: absolute;
  width: 100%;
  height: 60px;
  z-index: 10000;
  left: 0px;
  background-color: white;
  top: 0px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
