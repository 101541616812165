@import "./common.scss";

body {
  min-width: 900px !important;
}

.ant-input,
.ant-picker,
.ant-input-number,
.ant-select-selector {
  //height: 34px !important;
  border-radius: 4px !important;
}
.ant-select-multiple .ant-select-selector {
  height: auto !important;
}
.select-border-color > .ant-select-selector {
  border-color: $bg--primary !important;
  color: $bg--primary;
  width: 192px !important;
}
.ant-select-arrow {
  color: #3d5170 !important;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-form {
  color: $bg--gray !important;
}
.card_search > .ant-card-body {
  padding: 14px 16px;
}
.ant-card-meta-title {
  white-space: normal !important;
}
.ant-card,
.ant-table {
  height: 100%;
  color: $bg--gray !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.ant-table {
  font-size: 12px !important;
}

.ant-table .ant-btn {
  font-size: 12px !important;
  padding: 1px 10px !important;
}

.ant-table .ant-tag {
  font-size: 12px !important;
  padding: 4px 5px !important;
}

.ant-layout-sider {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-layout {
  background-color: white !important;
  min-height: calc(100vh) !important;
}
.ant-layout-header {
  height: 54px !important;
  line-height: 44px !important;
  border-bottom: 1px solid #e4dfdf;
  padding: 3px 0 !important;
}
.ant-layout-sider-trigger {
  display: none;
}
.ant-popover-inner {
  width: 455px;
}
.ant-popover-inner-content {
  padding: 0px !important;
}
.ant-layout-content {
  padding: 20px 40px 20px 40px;
  background: $bg--page;
}
.ant-table-thead > tr {
  //box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1) !important;
  //height: 56px;
  //padding: 0 16px;
}
.ant-table-thead > tr > th {
  background: #ffffff !important;
  color: #252733 !important;
  font-weight: bold !important;
  border-right: 0 !important;
  border-bottom: 3px solid #dadada !important;
}
//.ant-table-thead > tr > th:last-child {
//  border-right: 0 !important;
//}
.ant-table-tbody > tr > td {
  padding: 0 16px !important;
  word-wrap: break-word !important;
  max-width: 300px;
  border-right: 0 !important;
}

.ant-table-tbody > tr:nth-child(odd) {
  background-color: #f7f9fc;
}

.ant-pagination-next .ant-pagination-item-link {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.ant-pagination-prev .ant-pagination-item-link {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ant-pagination {
  margin-bottom: 0 !important;
  font-weight: 400;
  .ant-pagination-item {
    min-width: 40px;
    border-radius: 0;
  }
  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    background-color: #00A0FE;
    //border-color: $bg--primary;
    a {
      color: #ffffff !important;
    }
    //a:hover {
    //  color: $bg--primary;
    //}
  }
}

.ant-pagination-options .ant-select-selector{
  height: 32px !important;
}

.ant-select-selection-item, .ant-pagination a{
  color: #6c757d !important;
}

.info > .ant-modal-content > .ant-modal-close {
  color: $bg--primary !important;
}
.confirm > .ant-modal-content > .ant-modal-close {
  color: $bg--info !important;
}
.success > .ant-modal-content > .ant-modal-close {
  color: $bg--success !important;
}
.warning > .ant-modal-content > .ant-modal-close {
  color: $bg--warning2 !important;
}
.info > .ant-modal-content > .ant-modal-header {
  background-color: #f2f5f9 !important;
  border-bottom: 1px solid $bg--primary !important;
  .ant-modal-title {
    font-size: 20px;
    color: $bg--primary !important;
  }
}
.confirm > .ant-modal-content > .ant-modal-header {
  background-color: rgba(90, 168, 219, 0.2) !important;
  border-bottom: 1px solid $bg--info !important;
  .ant-modal-title {
    font-size: 20px;
    color: $bg--info !important;
  }
}
.success > .ant-modal-content > .ant-modal-header {
  background-color: rgba(102, 175, 28, 0.2) !important;
  border-bottom: 1px solid $bg--success !important;
  .ant-modal-title {
    font-size: 20px;
    color: $bg--success !important;
  }
}
.warning > .ant-modal-content > .ant-modal-header {
  background-color: rgba(254, 201, 67, 0.2) !important;
  border-bottom: 1px solid $bg--warning2 !important;
  .ant-modal-title {
    font-size: 20px;
    color: $bg--warning2 !important;
  }
}
.customNotify {
  cursor: pointer;
  & > .ant-badge-count {
    background-color: $bg--warning;
    cursor: pointer;
  }
}
.ant-table-cell:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme="dark"] .ant-table-cell:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

// checkbox
.ant-checkbox .ant-checkbox-inner {
  border-color: #A0A9B1!important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0080CB !important;
  border-color: #0080CB !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #A0A9B1!important;
  box-shadow: 0 0 2px 2px rgba(0, 128, 203, 0.2);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #0080CB !important;
}

.App .ant-checkbox-disabled .ant-checkbox-inner,
.ant-modal .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: rgba(170, 187, 202, 0.3) !important;
  border-color: #DFE6EC !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #A0A9B1 !important;
}

// tree checkbox
.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #A0A9B1!important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #0080CB !important;
  border-color: #0080CB !important;
}

.ant-tree-checkbox-inner:hover {
  border-color: #A0A9B1!important;
  box-shadow: 0 0 2px 2px rgba(0, 128, 203, 0.2);
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #0080CB !important;
}

.ant-modal .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: rgba(170, 187, 202, 0.3) !important;
  border-color: #DFE6EC !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #A0A9B1 !important;
}

.ant-tree-treenode-disabled .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #A0A9B1 !important;
}

// radio
.ant-radio .ant-radio-inner {
  border-color: #A0A9B1!important;
}

.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-checkbox-inner {
  box-shadow: 0 0 2px 2px rgba(0, 128, 203, 0.2);
}

.ant-radio-checked .ant-radio-inner {
  border-color: #0080CB !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #0080CB !important;
}

// react-input-tags
.react-tag-input__tag {
  padding: 4px 10px !important;
  color: rgba(68, 68, 68, 1) !important;
  border-radius: 15px !important;
  background: rgba(230, 240, 255, 0.7) !important;
}
.react-tag-input__tag__remove {
  background-color: transparent !important;
}
.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background-color: $bg--primary !important;
}

.ant-menu-submenu-title span.hide-tooltip {
  display: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td,
 {
  padding: 0px 5px !important;
  height: 35px;
}

.ant-table-column-sorters {
  padding: 0px !important;
}

.ant-table-thead > tr {
  height: 35px;
  border-bottom: 3px solid #dfe6ec;
}

.ant-table-tbody > tr {
  height: 35px;
  border-bottom: 1px solid #dfe6ec;
}

.ant-table-footer {
  padding: 8px 16px !important;
}

.ant-tooltip-inner {
  color: #ffffff !important;
  background-color: #479ACA !important;
}

.ant-tooltip-arrow-content{
  background-color: #479ACA !important;
}

.ant-select-item:nth-child(even) {
  background-color: #f7f9fc;
}

.ant-select-item {
  border-bottom: 1px solid #dfe6ec;
}

.ant-input[disabled], .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background: rgba(170, 187, 202, 0.3) !important;
  color: #a0a9b1 !important;
}

.headerSelect.select--is-disabled {
  color: #a0a9b1 !important;

  .select__control--is-disabled {
    background: rgba(170, 187, 202, 0.3) !important;

    .select__single-value--is-disabled {
      color: #a0a9b1 !important;
    }
  }
}

.ant-input-number-disabled {
  background: rgba(170, 187, 202, 0.3) !important;
  color: #a0a9b1 !important;
}

.ant-menu-inline-collapsed {
  width: 64px !important;
}

.ant-table,
.ant-table-empty {
  box-shadow: none!important;
}

.card-table .ant-card-body {
  padding: 16px!important;
}

.card-table .ant-row-start {
  margin: -12px 0px 12px 0px!important;
}

.card-table .row-table {
  margin: -12px 0px 0px 0px!important;
}

.card-table .empty-table::after {
  content:"";
  height: 35px;
}

.card-table .total-record {
  position: absolute;
  bottom: 8px;
}

.card-table .count-records {
  color: #6C757D!important;
}

.card-table .size-changer {
  padding: 12px 0px!important;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none!important;
  border-top: 1px solid #f0f0f0!important;
}

.ant-table-cell-scrollbar {
  box-shadow: none!important;
}